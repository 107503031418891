import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Text from "../../text.json"
import { Alert, InputGroup, Badge, FormControl, Button } from "react-bootstrap"

const FormSuccess = ({ french, message, subMessage, isJobForm }) => {
  const [email, setEmail] = useState(null)
  const [subscribed, setSubscribed] = useState(false)
  function handleSubmit() {
    addToMailchimp(email)
    setSubscribed(true)
  }

  FormSuccess.defaultProps = {
    isJobForm: true
  }

  return (
  <Alert className="formSuccess" variant="success">
    <Alert.Heading>{message}</Alert.Heading>
    <p>{subMessage}</p>
    <hr />
    <p className="mb-0">
      {Text[french ? "fr" : "en"].forms["submitted__subscribe"]}
  </p>
    { isJobForm && <InputGroup className="mt-3 w-100">
                  {subscribed ? (
                    <Badge className="newsletter__badge" variant="success">
                      {" "}
                      {
                        Text[french ? "fr" : "en"]["homepage-steps"].newsletter
                          .subscribed
                      }{" "}
                    </Badge>
                  ) : (
                    <>
                      <FormControl
                        type="email"
                        placeholder={
                          Text[french ? "fr" : "en"]["homepage-steps"]
                            .newsletter.email
                        }
                        className="email-text"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={e => setEmail(e.target.value)}
                        onKeyUp={e => {
                          if (e.key === "Enter") handleSubmit()
                        }}
                      />
                      <InputGroup.Append>
                        <Button
                          className="newsletter__button"
                          onClick={() => handleSubmit()}
                          variant="outline-secondary"
                        >
                          {
                            Text[french ? "fr" : "en"]["homepage-steps"]
                              .newsletter.subscribe
                          }
                        </Button>
                      </InputGroup.Append>
                    </>
                  )}
                </InputGroup>}
  </Alert>)
}

export default FormSuccess
