import React from "react"
import MultiJobForm from "../components/multiJobForm"
import Text from "../text.json"
import { Modal } from "react-bootstrap"

const ModalForm = ({ french, jobTitles, show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="multiform__title"
          id="contained-modal-title-vcenter"
        >
          {Text[french ? "fr" : "en"].header["modal-header"]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MultiJobForm jobTitles={jobTitles} french={french} />
      </Modal.Body>
    </Modal>
  )
}

export default ModalForm
