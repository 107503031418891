import React, { useState, useRef } from "react"
import FormSuccess from "../components/shared/formSuccess"
import ApplicationForm from "../components/shared/form"
import Text from "../text.json"
import { Form } from "react-bootstrap"
import { sendJobForm } from "../utils/formSubmission"
import "../components/styles/forms.scss"

let renderOptions = jobTitles => {
  let options = []
  options.push(
    <option key="empty" value="">
      . . .
    </option>
  )
  jobTitles.forEach((jobTitle, i) =>
    options.push(
      <option key={i} value={jobTitle}>
        {jobTitle}
      </option>
    )
  )
  return options
}

const MultiJobForm = ({ french, jobTitles }) => {
  const inputRef = useRef(null)

  const [jobTitle, setJobTitle] = useState(null)
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [file, setFile] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [address, setAddress] = useState(null)
  const [city, setCity] = useState(null)
  const [gender, setGender] = useState(null)
  const [citizenShip, setCitizenship] = useState(null)
  const [validPermit, setValidPermit] = useState(null)
  const [languages, setLanguages] = useState(new Set())
  const [province, setProvince] = useState(null)

  function handleLanguage(lang) {
    let newLanguages = languages.has(lang)
      ? languages.delete(lang)
      : languages.add(lang)
    setLanguages(new Set(newLanguages))
  }

  const handleSubmit = e => {
    e.preventDefault()
    let languagesSpoken = Array.from(languages).join()
    let newFormData = new FormData()
    newFormData.set("langue du poste", french ? "french" : "english")
    newFormData.set("job", jobTitle)
    newFormData.set("email", email)
    newFormData.set("name", name)
    newFormData.set("address", `${address} ${city}, ${province}`)
    newFormData.set("phone number", phoneNumber)
    newFormData.set("gender", gender)
    newFormData.set("citizenship", citizenShip)
    newFormData.set("permit", validPermit)
    newFormData.set("languages", languagesSpoken)
    newFormData.append("file", file)
    sendJobForm(newFormData)
    setFormSubmitted(true)
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formSubmitted ? (
        <FormSuccess
        french={french}
          message={`${Text[french ? "fr" : "en"].forms["submitted__heading"]} ${jobTitle}`}
          subMessage={`${Text[french ? "fr" : "en"].forms["submitted__submessage-1"]} ${jobTitle} ${Text[french ? "fr" : "en"].forms["submitted__submessage-2"]}`}
          isJobForm={true}
        />
        
      ) : (
        <>
          <Form.Group controlId="selectJob">
            <Form.Label>
              {Text[french ? "fr" : "en"].forms.select__label}
            </Form.Label>
            <Form.Control
              as="select"
              custom
              required
              onChange={e => setJobTitle(e.target.value)}
            >
              {renderOptions(jobTitles)}
            </Form.Control>
          </Form.Group>
          <ApplicationForm
            french={french}
            inputRef={inputRef}
            setName={setName}
            email={email}
            setEmail={setEmail}
            file={file}
            setFile={setFile}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            province={province}
            setProvince={setProvince}
            gender={gender}
            setGender={setGender}
            setCitizenship={setCitizenship}
            setValidPermit={setValidPermit}
            handleLanguage={handleLanguage}
            handleClick={handleClick}
          />
        </>
      )}
    </Form>
  )
}

export default MultiJobForm
