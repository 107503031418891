import React from "react"
import PropTypes from "prop-types"
import Text from "../../text.json"
import { Button, Form, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload } from "@fortawesome/free-solid-svg-icons"

const ApplicationForm = ({
  french,
  inputRef,
  setName,
  email,
  setEmail,
  file,
  phoneNumber,
  setPhoneNumber,
  setFile,
  address,
  setAddress,
  city,
  setCity,
  province,
  setProvince,
  setGender,
  setCitizenship,
  setValidPermit,
  handleLanguage,
  handleClick,
}) => {
  return (
    <>
      <Form.Group as={Row} controlId="formName">
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms.name__label}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            placeholder={Text[french ? "fr" : "en"].forms.name__placeholder}
            onChange={e => {
              setName(e.target.value)
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formEmail">
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms.email__label}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="email"
            placeholder={Text[french ? "fr" : "en"].forms.email__placeholder}
            value={email || ""}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} onChange={e => setFile(e.target.files[0])}>
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms.resume__label}
        </Form.Label>
        <Col>
          <div
            role="button"
            tabIndex="0"
            className="form__upload"
            onClick={() => handleClick()}
            onKeyDown={() => handleClick()}
          >
            <FontAwesomeIcon size="xs" icon={faUpload}></FontAwesomeIcon>
            <span>
              {file
                ? `${file.name}`
                : Text[french ? "fr" : "en"].forms["resume__upload-text"]}
            </span>
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            name="image"
            ref={inputRef}
          ></input>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms.phone__label}
        </Form.Label>
        <Col>
          <Form.Control
            value={phoneNumber ? phoneNumber : ""}
            onChange={e => setPhoneNumber(e.target.value)}
            type="number"
          ></Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms.address__label}
        </Form.Label>
        <Col>
          <Form.Control
            value={address ? address : ""}
            onChange={e => {
              setAddress(e.target.value)
            }}
            placeholder={
              Text[french ? "fr" : "en"].forms["address__address-text"]
            }
            type="text"
          ></Form.Control>
        </Col>
        <Col>
          <Form.Control
            value={city ? city : ""}
            onChange={e => setCity(e.target.value)}
            placeholder={Text[french ? "fr" : "en"].forms["address__city-text"]}
            id="city"
            type="text"
          ></Form.Control>
        </Col>
        <Col>
          <Form.Control
            value={province ? province : ""}
            onChange={e => setProvince(e.target.value)}
            placeholder={
              Text[french ? "fr" : "en"].forms["address__province-text"]
            }
            id="province"
            type="text"
          ></Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms["sex__label"]}
        </Form.Label>
        <Col className="d-flex align-items-center">
          <Form.Check
            onChange={() => setGender("male")}
            name="gender"
            type="radio"
            inline
            label={Text[french ? "fr" : "en"].forms["male__label"]}
            id="male-id"
          ></Form.Check>
          <Form.Check
            onChange={() => setGender("female")}
            name="gender"
            type="radio"
            inline
            label={Text[french ? "fr" : "en"].forms["female__label"]}
            id="female-id"
          ></Form.Check>
          <Form.Check
            onChange={() => setGender("non-binary")}
            name="gender"
            type="radio"
            inline
            label={Text[french ? "fr" : "en"].forms["binary__label"]}
            id="binary-id"
          ></Form.Check>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms["status__label"]}
        </Form.Label>
        <Col className="d-flex align-items-center">
          <Form.Check
            onChange={() => setCitizenship("Canadian Citizen")}
            name="status"
            type="radio"
            inline
            label={Text[french ? "fr" : "en"].forms["canadian__label"]}
            id="citizen"
          ></Form.Check>
          <Form.Check
            onChange={() => setCitizenship("Canadian Resident")}
            name="status"
            type="radio"
            inline
            label={Text[french ? "fr" : "en"].forms["resident__label"]}
            id="resident"
          ></Form.Check>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms["language__label"]}
        </Form.Label>
        <Col className="d-flex align-items-center">
          <Form.Check
            onChange={() => handleLanguage("english")}
            inline
            label={Text[french ? "fr" : "en"].forms["english__label"]}
            id="english-id"
          ></Form.Check>
          <Form.Check
            onChange={() => handleLanguage("french")}
            inline
            label={Text[french ? "fr" : "en"].forms["french__label"]}
            id="french-id"
          ></Form.Check>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          {Text[french ? "fr" : "en"].forms["liscense__label"]}
        </Form.Label>
        <Col className="d-flex align-items-center">
          <Form.Check
            onChange={() => setValidPermit("yes")}
            name="driverLiscense"
            type="radio"
            inline
            label={Text[french ? "fr" : "en"].forms["yes__label"]}
            id="yes"
          ></Form.Check>
          <Form.Check
            onChange={() => setValidPermit("no")}
            name="driverLiscense"
            type="radio"
            inline
            label={Text[french ? "fr" : "en"].forms["no__label"]}
            id="no"
          ></Form.Check>
        </Col>
      </Form.Group>
      <Form.Check
        type="checkbox"
        id="requirements"
        label={Text[french ? "fr" : "en"].forms.guarantee__requirements}
        required
      />
      <Form.Check
        type="checkbox"
        id="guarantee"
        label={Text[french ? "fr" : "en"].forms.guarantee__age}
        required
      />
      <Button className="form__button mt-2" variant="primary" type="submit">
        {Text[french ? "fr" : "en"].forms.button__submit}
      </Button>
    </>
  )
}

ApplicationForm.prototype = {
  french: PropTypes.bool.isRequired,
  setName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  file: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
}

// french,
// inputRef,
// setName,
// email,
// setEmail,
// file,
// setFile,
// handleClick,
// phoneNumber,
// setPhoneNumber,
// address,
// setAddress,
// city,
// setCity,
// province,
// setProvince,
// setGender,
// setCitizenship,
// setValidPermit,
// handleLanguage,

export default ApplicationForm
