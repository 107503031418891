import axios from "axios"

export let sendJobForm = formData => {
  axios({
    method: "POST",
    url: "https://getform.io/f/bacd9b22-a7ca-4beb-bbfc-ba5e001c148a",
    data: formData,
    header: {
      "Content-Type": "multipart/form-data;",
    },
  })
}

export let sendContactForm = formData => {
  axios({
    method: "POST",
    url: "https://getform.io/f/bacd9b22-a7ca-4beb-bbfc-ba5e001c148a",
    data: formData,
    header: {
      "Content-Type": "multipart/form-data;",
    },
  })
}
