import PropTypes from "prop-types"
import React, { useState } from "react"
import Text from "../text.json"
import HamburgerSVG from "../images/Hamburger.svg"
import TeaRockLogo from "../images/teaRockLogo.png"
import ModalForm from "./modal-form-header"
import { Link } from "gatsby"
import "./styles/header.scss"

const Header = ({ french, allJobs }) => {
  const [navOpen, setNavOpen] = useState(false)
  const [modalShow, setModalShow] = React.useState(false)

  let urlPrefix = french ? "" : "/en"

  let getJobTitles = allJobs => {
    let jobTitles = []
    allJobs.forEach(job => jobTitles.push(job.node.title))
    return jobTitles
  }

  let closedNav = (
    <div className="mobile__navbar--closed">
      <Link to={french ? "/" : "/en"}>
        <img
          className="mobile__navbar--logo"
          src={TeaRockLogo}
          alt="Tea Rock Logo"
        ></img>
      </Link>
      <div
        role="button"
        tabIndex="0"
        onKeyDown={() => setNavOpen(!navOpen)}
        onClick={() => setNavOpen(!navOpen)}
      >
        <img src={HamburgerSVG} alt="Menu button"></img>
      </div>
    </div>
  )

  let openNav = (
    <div className="mobile__navbar--opened">
      <div className="mobile__navbar--actions">
        <div className="navigation__language navigation__language--right">
          <button className="button--apply" onClick={() => setModalShow(true)}>
            {Text[french ? "fr" : "en"].header.apply}
          </button>
          <Link to={french ? "/en" : "/"}>
            {Text[french ? "fr" : "en"].header.language}
          </Link>
        </div>
        <div
          role="button"
          tabIndex="0"
          onKeyDown={() => setNavOpen(false)}
          onClick={() => setNavOpen(false)}
        >
          <p>X</p>
        </div>
      </div>
      <div className="mobile__navigation--links">
        <Link to={`${urlPrefix}/jobs`} className="navigation-link">
          {Text[french ? "fr" : "en"].header.opportunities}
        </Link>
        <Link to={`${urlPrefix}/benefits`} className="navigation-link">
          {Text[french ? "fr" : "en"].header.benefits}
        </Link>
        <Link to={`${urlPrefix}/contact`} className="navigation-link">
          {Text[french ? "fr" : "en"].header.contact}
        </Link>
        <Link to={`${urlPrefix}/contact-employer`} className="navigation-link">
          {Text[french ? "fr" : "en"].header.employer}
        </Link>
      </div>
    </div>
  )

  return (
    <>
      <header className="navigation__desktop">
        <div className="navigation__logo">
          <Link to={french ? "/" : "/en"}>
            <img src={TeaRockLogo} alt="Tea Rock Logo"></img>
          </Link>
        </div>
        <div className="navigation__link--container">
          <Link to={`${urlPrefix}/jobs`} className="navigation-link">
            {Text[french ? "fr" : "en"].header.opportunities}
          </Link>
          <Link to={`${urlPrefix}/benefits`} className="navigation-link">
            {Text[french ? "fr" : "en"].header.benefits}
          </Link>
          <div className="navigation__contact">
            <span>{Text[french ? "fr" : "en"].header.contact}</span>
            <div className="navigation__dropdown">
              <Link className="navigation-link" to={`${urlPrefix}/contact`}>
                {Text[french ? "fr" : "en"].header.employee}
              </Link>
              <Link className="navigation-link" to={`${urlPrefix}/contact-employer`}>
                {Text[french ? "fr" : "en"].header.employer}
              </Link>
            </div>
          </div>
        </div>
        <div className="navigation__language">
          <Link to={french ? "/en" : "/"}>
            {Text[french ? "fr" : "en"].header.language}
          </Link>
          <button className="button--apply" onClick={() => setModalShow(true)}>
            {Text[french ? "fr" : "en"].header.apply}
          </button>
        </div>
      </header>

      <header className="navigation__mobile">
        {navOpen ? openNav : closedNav}
      </header>
      <ModalForm
        french={french}
        jobTitles={getJobTitles(allJobs)}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  allJobs: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
