import React from "react"
import "./styles/footer.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

const Footer = ({ french }) => {
  return (
    <div className="footer__container">
      <div className="footer__socials">
        <a
          rel="noreferrer noopener"
          target="_blank"
          href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Ftearock.ca%2F%3Ffbclid%3DIwAR2pAY7IWemGCBlDRfC1f94Z0NcFh9z47UTbN_3cJECN2zcySnlLhDwKJSY&h=AT1_TrERT165m0bzNmf1PipirVGum-p1JgsLNmwTgvARbmXEoajjrPVf4aB1VfkaC2iEI_tchKIhVVAIIUVbpEGS7T7Pi377SJpzDdQZJJrbZQKB2H_YR5BsbEHlig"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          rel="noreferrer noopener"
          target="_blank"
          href="https://www.facebook.com/TeamTeaRock"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a 
          rel="noreferrer noopener"
          target="_blank"
          href="https://www.linkedin.com/in/tea-rock-8791341ba/"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
      {french ? "© Site réalisé par " : "© Website by "}
      <a
        className="footer_developer-link"
        rel="noreferrer noopener"
        target="_blank"
        href="https://www.ragith.com"
      >
        Ragith
      </a>
      2020
    </div>
  )
}

export default Footer
